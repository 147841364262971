
<template>



    <v-card :class="['mt-1 p-5', !darkTheme ? 'dark-theme2' : 'bright-theme2']">
      <!-- <div v-for="(apiList, category) in categorizedApiLists" :key="category" :class="!darkTheme ? 'dark-theme2' : 'bright-theme2'"> -->
      <div v-for="(apiList, category) in categorizedApiLists" :key="category" :class="['mt-0', !darkTheme ? 'dark-theme2' : 'bright-theme2']">
        <v-data-table v-if="apiList.length > 0" :headers="headers" :items="apiList" hide-default-footer  :class="['', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
            <template v-slot:top>
                <v-toolbar flat border class="rounded-lg">
                    <!-- Left-aligned content -->
                    <v-row align="center" :class="['', !darkTheme ? 'dark-theme' : 'bright-theme']">
                    <v-col cols="6">
                        <v-toolbar-title class="text-left font-family-monospace m-2">API</v-toolbar-title>
                    </v-col>
                    <v-divider inset vertical></v-divider>
                    <v-col cols="6">
                        <!-- Right-aligned content -->
                        <v-toolbar-title class="text-left font-family-monospace m-2">{{ category.toUpperCase() }}</v-toolbar-title>
                    </v-col>
                    </v-row>
                </v-toolbar>
            </template>

          <template v-slot:item="{ item }">
            <tr @click="closeResponse(item)">
              <td style="width: 50%;text-align: left; font-family: monospace; background-color: #ffffff; padding: 10px;" :class="['', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
                    <div style="display: flex; align-items: center;">
                     {{ getFuncName(item) }}<b>{{ formatInputArgumentsRaw(item) }}</b>
                    </div>
                </td>

              
              <td style="text-align: right; font-family: monospace; background-color: #f0f0f0; padding: 10px;">
                    <div style="display: flex; align-items: center;">
                        <i class="mdi mdi-information" style="color: grey; font-size: 20px;"></i>
                        <pre style="margin: 0; margin-left: 5px;">{{ formatDescription(item.description).toUpperCase() }}</pre>
                    </div>
                </td>

  

  
            </tr>
            <tr v-if="item.showResponse">
              <td :colspan="headers.length+2"> <!-- Increase colspan to accommodate the new column -->
                <v-text-field v-model="item.outputText" label="Output" readonly></v-text-field>
              </td>
            </tr>
          </template>
  
  
        </v-data-table>


        <v-card :class="['mt-4 mb-3 p-4 rounded-lg', !darkTheme ? 'dark-theme5' : 'bright-theme5']" elevation="2" >
   
   
              <!-- <v-card-title class="sandbox-title mt-5 sandbox-card">Sandbox</v-card-title> -->
              <VRow justify="center">
              <VCol>
                  <v-card elevation="0" :class="['api-card', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
                  <!-- <v-card-title class="api-title">API Setup</v-card-title> -->
                  <VRow align="center">
                      <VCol>
                      <v-select class="custom-text-field m-3" prepend-icon="mdi-text-box" variant="outlined" v-model="this.fieldIndices[category].selectedEvent" :items="apiList.map(api => api.name)" label="Select Event" @update:modelValue="handleSelectClick(category)"></v-select>
                      </VCol>
                      <VCol style="display: flex; align-items: center;justify-content: center;">
                        <v-text-field
                          v-model="this.fieldIndices[category].customTextInputs"
                          label="Custom Arguments"
                          variant="outlined"
                          prepend-icon="mdi-code-braces"
                          class="custom-text-field m-3"
                          :disabled="this.fieldIndices[category].customTextInputs ? false : true"
                        ></v-text-field>
                      </VCol>

                  
                  </VRow>


                  <VRow align="center">
                    <VCol cols="11"> <!-- 2/3 of the width -->
                      <v-textarea
                        :id="'textAreaContent' + category"
                        :value="this.fieldIndices[category].customTextInputs ? `${this.fieldIndices[category].selectedEvent}(${this.fieldIndices[category].customTextInputs})` : this.fieldIndices[category].selectedEvent ? `${this.fieldIndices[category].selectedEvent}()` : `${this.fieldIndices[category].selectedEvent}`"
                        readonly
                        variant="outlined"
                        dense
                        hide-details
                        auto-grow
                        prepend-icon="mdi-function"
                        rows="1"
                        style="font-family: monospace; white-space: pre-wrap;"
                        append-icon="mdi-content-copy"
                        @click:append="copyToClipboard(category)"
                        class="custom-text-field ml-3"
                      ></v-textarea>
                    </VCol>
                    <VCol cols="1" align="center"> <!-- 1/3 of the width -->
                      <!-- <v-btn class="ml-0 mt-3 mr-3" @click="copyToClipboard" color="primary" icon >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn> -->
                      <v-btn class="mt-1 mr-2 mb-0" @click="callCustomFunction(category)" color="primary">Call</v-btn>
                
                    </VCol>
                  </VRow>




                  <VRow justify="center" class="m-0">
                    <v-col cols="12" md="8">
                        <v-card class="api-card mb-0" elevation="0" :class="['', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
                            <v-card-title class="api-title">API Result</v-card-title>
                            <v-card-text>
                              <v-textarea
                                v-model="this.fieldIndices[category].liveHookResult"
                                readonly
                                variant="outlined"
                                prepend-icon="mdi-api"
                                dense
                                hide-details
                                :rows="calculateRows(category)"
                                style="font-family: monospace; white-space: pre-wrap;"
                            ></v-textarea>



                            </v-card-text>
                        </v-card>
                    </v-col>
                </VRow>


                  </v-card>
              </VCol>
              </VRow>


     
          
        </v-card>
  
        <!-- <v-divider v-if="category === 'nodes'"></v-divider>
        <v-divider v-if="category === 'nodes'"></v-divider> -->
      </div>
  
  
  
     
  
  
  
  
  
    </v-card>
  </template>
  
  <script>
  export default {
    props: {
      darkTheme: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
          // eventOptions: [],
        //   textAreaContent: '',
          // selectedEvent: '',
          // customTextInputs: '',
          // liveHookResult: '',
          fieldIndices: {
            'storage': {
              "liveHookResult":'',
              "customTextInputs":'',
              "textAreaContent":'',
              "selectedEvent":''
            },
            'multisig': {
              "liveHookResult":'',
              "customTextInputs":'',
              "textAreaContent":'',
              "selectedEvent":''
            },
            'supply': {
              "liveHookResult":'',
              "customTextInputs":'',
              "textAreaContent":'',
              "selectedEvent":''
            },
            'nodes': {
              "liveHookResult":'',
              "customTextInputs":'',
              "textAreaContent":'',
              "selectedEvent":''
            }
          },
          headers: [
            { text: 'Function', value: 'name' },
            { text: 'Description', value: 'description' },
            // { text: '', value: 'args' },
            // { text: 'Checkbox', value: 'checkbox' },
            // { text: 'Input Arguments', value: 'argumentsInput' },
            // { text: 'Call', value: 'callButton' },
          ],
          apiList: window.cubits.api.data.apiDescriptions,
          search: '',
          outputText: '',
      };
    },
    methods: {
      handleSelectClick(category) {
        console.log("1",category)
        this.codeSnippet = this.fieldIndices[category].selectedEvent; 
        this.fieldIndices[category].customTextInputs = this.formatInputArguments(this.apiIndex[this.fieldIndices[category].selectedEvent]).replace("(","").replace(")","");
      },
      callCustomFunction(category) {
        console.log("1",category)
        // Find the selected function in the apiList
        const selectedFunction = this.apiIndex[this.fieldIndices[category].selectedEvent];  //this.apiList.find(api => api.name === this.selectedEvent);
        
        if (!selectedFunction) {
          this.fieldIndices[category].liveHookResult = 'Error: Selected function not found';
          return;
        }
  
  
        // Call the selected function dynamically
        this.fieldIndices[category].liveHookResult = 'Calling API...'; // Display a loading message
        
        window.cubits.api.data[selectedFunction.name.split('.')[4]][selectedFunction.name.split('.')[5]](...this.fieldIndices[category].customTextInputs.split(',').map(arg => arg.trim()))
          .then(response => {
            this.fieldIndices[category].liveHookResult = this.serializeObjectToString(response); 
          })
          .catch(error => {
            this.fieldIndices[category].liveHookResult = 'Error: ' + error.message;
          });
      },
      serializeObjectToString(obj) {
        console.log("objobjobjobj",obj)
        let result = '';
        let hasNonNumericKey = false;
        
        if (Array.isArray(obj)) {
            return obj.join('\n');
        }


        if (typeof obj === 'string') {
            return `${obj}\n`.trimEnd();
        }

        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[key];
                if (!(/^\d+$/.test(key)) && typeof value !== 'object') {
                    result += `${key}:${value}\n`;
                    hasNonNumericKey = true;
                } else if (typeof value === 'object') {
                    result += `${key}:${JSON.stringify(value)}\n`;
                    hasNonNumericKey = true;
                }
            }
        }

        return hasNonNumericKey ? result.trimEnd() : obj;
      },
      formatDescription(description) {
        return description.replace(/returns/g, '<b>returns</b>');
        // return description.toUpperCase().replace(/RETURNS/g, '<b>returns</b>');
      },
      formatInputArgumentsRaw(api) {
        const args = api.arguments.map(arg => {
        //   if (arg.type === 'boolean') {
        //     return api.checkboxValue || true;
        //   } else if (arg.name === 'decimals') {
        //     return api.decimalSelector != -1 ? api.decimalSelector : 10; // Replace with actual value for integers
        //   } else if (arg.name === 'wallet_address' || arg.name === 'wallet_id') {
        //     return "0x0000000000000000000000000000000000000000"; // Replace with actual value for integers
        //   } else {
        //     return api.argumentsInput;
        //   }
          if (arg.type) {
            return arg.name + "=" + arg.type;
          }

        });
  
        return `(${args.join(', ')})`;
      },
      formatInputArguments(api) {
        const args = api.arguments.map(arg => {
          if (arg.type === 'boolean') {
            return api.checkboxValue || true;
          } else if (arg.name === 'decimals') {
            return api.decimalSelector != -1 ? api.decimalSelector : 10; // Replace with actual value for integers
          } else if (arg.name === 'wallet_address' || arg.name === 'wallet_id') {
            return "0x0000000000000000000000000000000000000000"; // Replace with actual value for integers
          } else {
            return api.argumentsInput;
          }
        });
  
        return `(${args.join(', ')})`;
      },
      async callFunction(api) {
        try {
          if (window.cubits && window.cubits.api && window.cubits.api.data && window.cubits.api.data.nodes) {
            const args = api.arguments.map(arg => {
              if (arg.type === 'boolean') {
                return api.checkboxValue || false;
              } else if (arg.name === 'decimals') {
                return api.decimalSelector!= -1 ? api.decimalSelector : 15; // Replace with actual value for integers
              } else {
                // Handle other argument types if needed
                
                return api.argumentsInput;
              }
            });
  
            // this.outputText = await window.cubits.api.data[api.name.split(".")[4]][api.name.split(".")[5]](...args);
            api.outputText = await window.cubits.api.data[api.name.split(".")[4]][api.name.split(".")[5]](...args);
            // api.outputText += "\t"+await window.cubits.api.data[api.name.split(".")[4]][api.name.split(".")[5]](true);
            // api.outputText += "\t"+await window.cubits.api.data[api.name.split(".")[4]][api.name.split(".")[5]](true,5);
            // api.outputText = Number(api.outputText).toLocaleString().replace(/,/g, '')
            api.showResponse = true; // Show the response
          } else {
            console.error("Error: window.cubits or its properties are undefined.");
          }
        } catch (error) {
          console.error("Error executing function:", error);
        }
      },
      closeResponse(api) {
        api.showResponse = false; // Hide the response
      },
      changedCallback(api) {
        this.callFunction(api)
      },
      getFuncName(api) {
          let _name = api.name
          // if (api.checkboxValue) {
          //   _name +="("+api.checkboxValue
          // }
          // if (api.decimalSelector != -1) {
          //   _name +=","+api.decimalSelector
          //   _name +=")"
          // }
         return _name //!api.arguments.some(arg => arg.type === 'boolean')
  
      },
      calculateRows(category) {
        if (typeof this.fieldIndices[category].liveHookResult !== 'string') return 1; // Default to one row if not a string
        return this.fieldIndices[category].liveHookResult.split('\n').length;
      },
      copyToClipboard(_event,category) {
        console.log("_event",_event)
        // Get the content of the textarea
        // const textAreaContent = this.textAreaContent;
        const textAreaContent = document.getElementById("textAreaContent"+category).value;

        console.log("textAreaContent",textAreaContent)
  
        // Copy content to clipboard
        navigator.clipboard.writeText(textAreaContent)
          .then(() => {
            // Success message
            // this.$toast.success('Code snippet copied to clipboard');
            console.log('Code snippet copied to clipboard');
          })
          .catch((error) => {
            // Error message
            console.error('Error copying to clipboard:', error);
            // this.$toast.error('Error copying code snippet to clipboard');

          });
      }
  
      
    },
    computed: {
      // fieldIndices() {
      //   return {
      //     'storage': {
      //       "liveHookResult":'',
      //       "customTextInputs":'',
      //       "textAreaContent":'',
      //       "selectedEvent":''
      //     },
      //     'multisig': {
      //       "liveHookResult":'',
      //       "customTextInputs":'',
      //       "textAreaContent":'',
      //       "selectedEvent":''
      //     },
      //     'supply': {
      //       "liveHookResult":'',
      //       "customTextInputs":'',
      //       "textAreaContent":'',
      //       "selectedEvent":''
      //     },
      //     'nodes': {
      //       "liveHookResult":'',
      //       "customTextInputs":'',
      //       "textAreaContent":'',
      //       "selectedEvent":''
      //     },
      //   };
      // },

      categorizedApiLists() {
        return {
          'storage': this.apiList.filter(api => api.name.includes('window.cubits.api.data.storage')),
          'multisig': this.apiList.filter(api => api.name.includes('window.cubits.api.data.multisig')),
          'supply': this.apiList.filter(api => api.name.includes('window.cubits.api.data.supply')),
          'nodes': this.apiList.filter(api => api.name.includes('window.cubits.api.data.nodes')),
        };
      },
      storageApiList() {
        return this.apiList.filter(api => api.name.includes('window.cubits.api.data.storage'));
      },
      multisigApiList() {
        return this.apiList.filter(api => api.name.includes('window.cubits.api.data.multisig'));
      },
      supplyApiList() {
        return this.apiList.filter(api => api.name.includes('window.cubits.api.data.supply'));
      },
      nodesApiList() {
        return this.apiList.filter(api => api.name.includes('window.cubits.api.data.nodes'));
      },
    },
    mounted() {
        this.apiList = this.apiList.map(api => ({ ...api, outputText: '', showResponse: false, decimalSelector: -1 }));
        // this.eventOptions = this.apiList.map(api => api.name);
        this.apiIndex = {};
        // this.fieldIndices = {};
            
        // Iterate over the apiList array
        this.apiList.forEach(api => {
            // Set the API object in the apiIndex with its name as the key
            this.apiIndex[api.name] = api; // Direct assignment without using this.$set
        });

        // for (const name in this.categorizedApiLists) {
        //   console.log(`Category: ${name}`);
        //   console.log(`Category: ${name}`);
        //   console.log(`Category: ${name}`);
        //   console.log(`Category: ${name}`);
        //   this.fieldIndices[name] = {
        //     "liveHookResult":'',
        //     "customTextInputs":'',
        //     "textAreaContent":'',
        //     "selectedEvent":''
        //   }
        // }

        // this.fieldIndices["storage"] = {
        //     "liveHookResult":'',
        //     "customTextInputs":'',
        //     "textAreaContent":'',
        //     "selectedEvent":''
        //   }
        // console.log("this.fieldIndicess",this.fieldIndices)
        
    },


  };
  </script>
  
  <style>
  .v-text-field .v-field {
      background-color: white !important; /* Set your desired background color */
    }

    /* Hide the footer info section */
    .v-data-table-footer {
        display: none !important;
    }

  /* CSS for API Call */
  .sandbox-card {
    /* width: 100%;
    margin: auto;
    padding: 20px;
    border-radius: 10px; */
    border-top: 2px solid #5d5c5c !important;
    border-bottom: 2px solid #5d5c5c !important;
  }
  
  .sandbox-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .api-card {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
  }
  
  .api-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .custom-text-field .v-label {
    position: absolute;
    top: -7px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    font-size: 12px;
  }

  .custom-text-field .v-input__control {
    padding-top: 1px;
  }

  .custom-text-field .v-input__slot {
    padding-left: 10px;
  }
  </style>