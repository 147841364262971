
<template>

  <div class="network-specs-container">
    <h2 :class="['p-1', !darkTheme ? 'dark-theme' : 'bright-theme']">Network Specifications</h2>
    <div class="table-container">
      <table>
        <tr :class="['', !darkTheme ? 'dark-theme2' : 'bright-theme2']">
          <td>Algorithm</td>
          <td>Proof-of-Authority</td>
        </tr>
        <tr :class="['', !darkTheme ? 'dark-theme4' : 'bright-theme1']">
          <td>Target Block Time</td>
          <td>5 Seconds</td>
        </tr>
        <tr :class="['', !darkTheme ? 'dark-theme2' : 'bright-theme2']">
          <td>Network ID</td>
          <td>420500100</td>
        </tr>
        <tr :class="['', !darkTheme ? 'dark-theme4' : 'bright-theme1']">
          <td>RPC Server</td>
          <td>{{ rpcServer }}</td>
        </tr>
      </table>
    </div>
  </div>

  <v-divider class="border-opacity-100"></v-divider>

  <!-- <div class="additional-info-container">
    <h3>Additional Information</h3>
    <div class="additional-info">
      <div class="info-item">
        <h4>What is a Target Block Time?</h4>
        <p class="text-center">The target block time refers to the desired interval between the creation of consecutive blocks on the Storageprotocol network. It is set to 5 seconds, meaning that ideally, a new block should be mined every 5 seconds when there is activity on the network. However, if there is no activity, the network may wait until new transactions are initiated before creating a block.</p>
      </div>
      <div class="info-item">
        <h4>What is the Node Reward?</h4>
        <p class="text-center">The node reward refers to the compensation provided to Storageprotocol network participants who operate and maintain network nodes. These rewards are distributed for their contributions to network stability, transaction processing, and overall decentralization.</p>
      </div>
      <div class="info-item">
        <h4>What is the Storageprotocol Dev Reward?</h4>
        <p class="text-center">The Storageprotocol development reward is a designated portion of block rewards reserved for the Storageprotocol development fund. These rewards, totaling 2 CUBITS per block, are automatically allocated to support ongoing development efforts, including exchange listings, developer incentives, network maintenance, and other related expenses.</p>
      </div>
      <div class="info-item">
        <h4>What is the Network ID?</h4>
        <p class="text-center">The Network ID serves as a unique identifier for the Storageprotocol network. It distinguishes the Storageprotocol blockchain from other Ethereum-based forks and ensures compatibility with network operations, including node communication, transaction verification, and network configuration.</p>
      </div>
      <div class="info-item">
        <h4>What is the RPC Server?</h4>
        <p class="text-center">The Remote Procedure Call (RPC) Server enables users to interact with the Storageprotocol Geth node operated by the official Storageprotocol development team. Through the RPC interface, users can access blockchain data, submit transactions, query network status, and perform other operations essential for decentralized application (DApp) development and blockchain interaction.</p>
      </div>
    </div>
  </div> -->



  <v-container>
    <v-row>
      <v-col v-for="(group, index) in groups" :key="index" cols="12">
        <!-- <v-btn @click="toggleContent(index)">
          {{ group.buttonText }}
        </v-btn> -->
        <template v-if="group.showContent">
          <v-col v-for="(item, idx) in group.items" :key="idx" cols="12">
            <v-card class="mb-4 pt-4 rounded-lg" elevation="1">
              <v-img v-if="item.imageUrl" :src="item.imageUrl" height="600px">
                <!-- Optional: You can add overlay text or icon here -->
              </v-img>
              <!-- <v-card-text class="text-center">
                {{ item.description }}
              </v-card-text> -->
              <v-card-text class="text-center" v-html="item.description" v-if="item.description"></v-card-text>

              <v-card-actions class="justify-center" v-if="item.bottomdescription">
                <div v-html="item.bottomdescription"></div>
                <!-- (c) Storageprotocol 2024 -->
              </v-card-actions>
            </v-card>
          </v-col>
        </template>
      </v-col>
    </v-row>
  </v-container>




</template>

<script>
export default {
  props: {
      darkTheme: {
        type: Boolean,
        required: true
      }
    },
  data() {
    return {
      rpcServer: 'https://rpc.storageprotocol.com',
      groups: [
        // { 
        //   buttonText: 'Install Metamask',
        //   showContent: false,
        //   items: [
        //     { 
        //       imageUrl: 'https://raw.githubusercontent.com/StorageProtocol/Documentation/main/metamask_instructions_0.png', 
        //       description: 'Download the Latest Version.' 
        //     },
        //     { 
        //       imageUrl: 'https://raw.githubusercontent.com/StorageProtocol/Documentation/main/metamask_instructions_0b.png', 
        //       description: 'Register the SDK inside your Web Project' 
        //     },
        //     { 
        //       imageUrl: 'https://raw.githubusercontent.com/StorageProtocol/Documentation/main/metamask_instructions_0c.png', 
        //       description: 'Register the SDK inside your Web Project' 
        //     },
        //     { 
        //       imageUrl: 'https://raw.githubusercontent.com/StorageProtocol/Documentation/main/metamask_instructions_0d.png', 
        //       description: 'Register the SDK inside your Web Project' 
        //     },
        //     { 
        //       imageUrl: 'https://raw.githubusercontent.com/StorageProtocol/Documentation/main/metamask_instructions_1.png', 
        //       description: 'Register the SDK inside your Web Project' 
        //     }
        //   ]
        // },
//         { 
//           buttonText: 'Install a Node',
//           showContent: false,
//           items: [
//             { 
//               imageUrl: '', 
//               description:  `<h3 style="color:black">
//     Visit <a href="https://storageprotocol.com" target="_blank" rel="noopener noreferrer">STORAGEPROTOCOL.COM</a>
// </h3>`,
//               bottomdescription: '' 
//             },
//             { 
//               imageUrl: 'https://raw.githubusercontent.com/StorageProtocol/Documentation/main/install_node_0.png', 
//               description: 'Choose your Operating System.' ,
//               bottomdescription: '' 
//             },
//           ]
//         },
        { 
          buttonText: 'Install cubits SDK',
          showContent: true,
          items: [
            { 
              imageUrl: 'https://raw.githubusercontent.com/StorageProtocol/Documentation/main/download_sdk_1.png', 
              description: 'Download the Latest Version.' ,
              bottomdescription: '' 
            },
            { 
              imageUrl: 'https://raw.githubusercontent.com/StorageProtocol/Documentation/main/import_sdk_1.png', 
              description: 'Register the SDK inside your Web Project' ,
              bottomdescription: '' 
            }
          ]
        },
        // Add more groups as needed
      ]
    };
  },
  methods: {
    toggleContent(index) {
      this.groups.forEach((group, i) => {
        if (i === index) {
          group.showContent = !group.showContent;
        } else {
          group.showContent = false;
        }
      });
    },
    copyToClipboard(_event) {
      console.log("_event",_event)
      // Get the content of the textarea
      // const textAreaContent = this.textAreaContent;
      const textAreaContent = document.getElementById("textAreaContent").value;

      console.log("textAreaContent",textAreaContent)

      // Copy content to clipboard
      navigator.clipboard.writeText(textAreaContent)
        .then(() => {
          // Success message
          // this.$toast.success('Code snippet copied to clipboard');
          console.log('Code snippet copied to clipboard');
        })
        .catch((error) => {
          // Error message
          console.error('Error copying to clipboard:', error);
          // this.$toast.error('Error copying code snippet to clipboard');

        });
    }
  },
};
</script>

<style scoped>
.network-specs-container {
  margin: 50px auto;
  max-width: 500px;
}

h2, h3, h4 {
  text-align: center;
}

.table-container {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 8px;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

a {
  color: blue;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.additional-info-container {
  max-width: 500px;
  margin: 0 auto;
}

.additional-info {
  text-align: left;
}

.info-item {
  margin-bottom: 20px;
}
</style>
