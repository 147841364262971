<template>

  
              <v-card :class="['mt-1 rounded-lg', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
                <VRow>
                  
                  <VCol>
                    <v-card :class="['p-4', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
                      <!-- <v-card-title>Event Showcase: OnCreateContract</v-card-title> -->
                      <!-- <v-card-title>Event Showcase:<v-select v-model="selectedEvent" :items="eventOptions" label="Select Event"></v-select></v-card-title> -->
                      <!-- <v-card-title>Event Showcase:
                        <v-select v-model="selectedEvent" :items="eventOptions" label="Select Event" class="ml-2"></v-select>
                      </v-card-title> -->
                     
                   
                        <VRow>
                          <VCol> 
                            <v-select variant="outlined" v-model="selectedEvent" :items="eventOptions" label="Select Event" class="rounded-lg options-button" @click="handleSelectClick"></v-select>
      
                            <!-- <v-select v-model="selectedEvent" :items="eventOptions" label="Select Event" class="ml-2" style="max-width: 150px;" @click="handleSelectClick"></v-select> -->
                          </VCol>
                          <VCol class="d-flex justify-center "> 
                   
                            <div class="listening-live options-button" style="top:24px;font-size: 20px;">
                              <i class="fas fa-circle" :style="{ color: '#13d513' }"></i>
                              <span> Live | Event Listener</span>
                            </div>
                            <div class="listening-live options-button" style="top:85px;font-size: 18px;">
                              <i class="fas fa-cube" :style="{ color: '#4b4b4b' }"></i>
                              <span> Block: {{ getBlockNr() }}  </span>
                            </div>

                          </VCol>
                        </VRow>
               
                   
               
                        <VRow>
                          <VCol> 
                            <!-- <v-textarea v-model="codeSnippet" label="Code Snippet" readonly></v-textarea> -->
                            <v-textarea
                              v-model="codeSnippet"
                              label="Code Snippet"
                              readonly
                              variant="outlined"
                              dense
                              hide-details
                              auto-grow
                              rows="4"
                              class="options-button"
                            ></v-textarea>
                            <div class="mt-2 ml-1 options-button" style="display: flex; align-items: center;">
                                <i class="mdi mdi-information" style="color: grey; font-size: 20px;"></i>
                                <pre style="margin: 0; margin-left: 5px;">This Event Triggers: {{ApiListsNameToDescription[selectedEvent]?.description}}</pre>
                            </div>
                          
                            <v-btn class="mt-3 mb-2 ml-2" style="display: flex; align-items: center; float:right;" @click="copyToClipboard" color="primary">Copy to Clipboard</v-btn>
                          </VCol>

                          <VCol class="d-flex justify-center"> 
                            <!-- <v-textarea v-model="codeSnippet" label="Code Snippet" readonly></v-textarea> -->
                            <v-textarea
                              v-model="liveHookResult"
                              label="Waiting for Event..."
                              readonly
                              variant="outlined"
                              dense
                              hide-details
                              auto-grow
                              rows="4"
                              class="options-button"
                            ></v-textarea>

                            <!-- <v-btn @click="copyToClipboard" color="primary">Copy to Clipboard</v-btn> -->
                          </VCol>


                        </VRow>
                  
                    </v-card>
                  </VCol>
                </VRow>
              </v-card>
        


      <!-- <v-card class="m-1 rounded-lg"> -->
      <v-card :class="['', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
          <div>
            <div v-for="(apiList, category) in categorizedApiLists" :key="category" :class="['mt-0', !darkTheme ? 'dark-theme2' : 'bright-theme2']">
              <!-- <v-divider v-if="category !== 'NETWORK'"></v-divider> -->
              <!-- <v-divider></v-divider> -->

              <v-data-table v-if="apiList.length > 0" :headers="headers" :items="apiList" hide-default-footer :class="['', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
                <template v-slot:top>
                  <v-toolbar flat>

                    <v-row align="center" :class="['mt-0', !darkTheme ? 'dark-theme' : 'bright-theme']">
                      <v-col cols="6">
                          <v-toolbar-title class="text-left font-family-monospace m-2">EVENTS</v-toolbar-title>
                      </v-col>
                      <v-divider inset vertical></v-divider>
                      <v-col cols="6">
                          <!-- Right-aligned content -->
                          <v-toolbar-title class="text-left font-family-monospace m-2">{{ category.toUpperCase() }}</v-toolbar-title>
                      </v-col>
                      </v-row>

                  </v-toolbar>
                </template>

                <template v-slot:item="{ item }">
                    <tr @click="closeResponse(item)">
                      <td style="width: 50%;text-align: left; font-family: monospace; background-color: #ffffff; padding: 10px;" :class="['', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
                          <div style="display: flex; align-items: center;">
                            {{ getFuncName(item) }}
                          </div>
                      </td>

                    
                      <td style="text-align: right; font-family: monospace; background-color: #f0f0f0; padding: 10px;" >
                          <div style="display: flex; align-items: center;">
                              <i class="mdi mdi-information" style="color: grey; font-size: 20px;"></i>
                              <pre style="margin: 0; margin-left: 5px;">{{ item.description }}</pre>
                          </div>
                      </td>


                    </tr>

                  </template>


                  <!-- <template v-slot:item="{ item }">
                  <tr @click="closeResponse(item)">
                    <td >{{ getFuncName(item) }}<b>{{ formatInputArguments(item) }}</b></td>
                    <td>
                      <span v-html="formatDescription(item.description)"></span>
                    </td>
                  </tr>
                  <tr v-if="item.showResponse">
                    <td :colspan="headers.length+2">
                      <v-text-field v-model="item.outputText" label="Output" readonly></v-text-field>
                    </td>
                  </tr>
                </template> -->
              </v-data-table>
              <!-- <v-divider></v-divider> -->
              <!-- <v-divider v-if="category === 'STORAGE'"></v-divider> -->
              <!-- <v-divider v-if="category === 'STORAGE'"></v-divider> -->

            </div>
            
          </div>
      </v-card>



          

</template>

<script>
export default {
  props: {
    darkTheme: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      liveHookResult: '',
      selectedEvent: 'NewBlock',
      eventOptions: [],
      headers: [
        { text: 'Function Name', value: 'funcName' },
        { text: 'Description', value: 'description',sortable:false },
      ],
      ApiListsNameToDescription: {},
      categorizedApiLists: {
        NETWORK: [
          {
            funcName: 'NewBlock',
            description: 'A new block has been propagated on the blockchain network.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NewTransaction',
            description: 'A new transaction has been propagated on the blockchain network.',
            showResponse: false,
            outputText: '',
          },
        ],
        USDT: [
          {
            funcName: 'Mint',
            description: 'USDT token has been minted on the storage protocol chain via locking on a compatible cross-chain network.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'Burn',
            description: 'USDT token has been burned from the storage protocol chain via unlocking on a compatible cross-chain network.',
            showResponse: false,
            outputText: '',
          },
        ],
        CUBITS: [
          {
            funcName: 'BuyCubits',
            description: 'A wallet has converted USDT token to Cubits.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'SellCubits',
            description: 'A wallet has converted Cubits to USDT token.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'CreateNodeRewardCubits',
            description: 'The network has generated daily node rewards for node owners.',
            showResponse: false,
            outputText: '',
          },
        ],
        NODES: [
          {
            funcName: 'NodeReward',
            description: 'Node reward threshold has been met, and Cubits reward was sent to a specific wallet.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NodeRewardStack',
            description: 'Node reward threshold has not been met, but Cubits reward was marked to a specific wallet.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'DailyPayoutTrigger',
            description: 'Daily node payment was triggered.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NewPayoutThreshold',
            description: 'Threshold for node payments has been changed.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NewPayoutCount',
            description: 'Number of nodes paid per payment block has been changed.',
            showResponse: false,
            outputText: '',
          },
        ],
        MULTISIG: [
          {
            funcName: 'NewBridgeReceipt',
            description: 'A new bridge receipt has been generated by the multisig.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NewTransactionExecution',
            description: 'A new transaction has been executed by the multisig.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'TransactionFailure',
            description: 'A new transaction has failed by the multisig.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NewSignature',
            description: 'A new transaction signature has been submitted by a multisig member to the multisig.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'BadSignature',
            description: 'A new transaction signature has failed by a multisig member to the multisig.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'SignatureOverwrite',
            description: 'A new transaction signature has overwritten an existing one by a multisig member to the multisig.',
            showResponse: false,
            outputText: '',
          },
        ],
        STORAGE: [
          {
            funcName: 'OnNewContractHost',
            description: 'A new wallet has created the first storage contract.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'OnCreateContract',
            description: 'A new storage contract has been created by a wallet.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'OnRemoveContract',
            description: 'An existing storage contract has been removed by a wallet.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'OnExtendContract',
            description: 'An existing storage contract has been extended in online time by a wallet.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'OnContractExpired',
            description: 'An existing storage contract has expired its lifetime.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'OnChangedOwnership',
            description: 'An existing storage contract has changed ownership.',
            showResponse: false,
            outputText: '',
          },
        ],
      },
    }
  },
  computed: {
    categorizedEvents() {
      const events = [];
      for (const category in this.categorizedApiLists) {
        if (Object.prototype.hasOwnProperty.call(this.categorizedApiLists, category)) {
          this.categorizedApiLists[category].forEach((event) => {
            events.push(event.funcName);
            this.ApiListsNameToDescription[event.funcName] = event;
          });
        }
      }
      return events;
    },
    codeSnippet() {
      if (!this.selectedEvent) return ''; // Return empty string if no event is selected

      // Replace "oncreatecontract" with the selected event name
      return `window.cubits.on('${this.selectedEvent}', (data) => {
        console.log('----> New ${this.selectedEvent} event detected externally. Data:', data);
        // Your code to handle the ${this.selectedEvent} event
});`;
    },    
    codeSnippetDescription() {
      if (!this.selectedEvent) return ''; // Return empty string if no event is selected

      // Replace "oncreatecontract" with the selected event name
      return `window.cubits.on('${this.selectedEvent}', (data) => {
        console.log('----> New ${this.selectedEvent} event detected externally. Data:', data);
        // Your code to handle the ${this.selectedEvent} event
});`;
    }
  },
  methods: {
    getBlockNr() {
      if (window && window.cubits && window.cubits.lastBlockNumber !== null) {
          return window.cubits.lastBlockNumber;
      } else {
          return 'loading...';
      }
    },
    handleSelectClick() {
        // Your logic for handling the click event
        console.log("Select clicked!");
        this.liveHookResult = "";
    },
    populateEventOptions() {
      this.eventOptions = this.categorizedEvents;
    },
    copyToClipboard() {
      // Copy code snippet to clipboard
      navigator.clipboard.writeText(this.codeSnippet);
    },
    formatCurrentDateTime() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const year = currentDate.getFullYear().toString().substr(2,4);
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');

      return `${hours}:${minutes}:${seconds} @ ${day}.${month}.${year}`;
    },
    serializeObjectToString(obj) {
        let result = '';
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === 'object') {
                    result += `${key}:${JSON.stringify(obj[key])} - ${this.formatCurrentDateTime()}\n`;
                } else {
                    result += `${key}:${obj[key]} - ${this.formatCurrentDateTime()}\n`;
                }
            }
        }
        return result.trim(); // Trim any trailing newline
    },
    liveHook(data) {
      // Simulate live hook result (replace this with actual logic)
      console.log("aaaaaaaaaaaaadata",data)
      this.liveHookResult += this.serializeObjectToString(data) +"\n";
    },
    getFuncName(item) {
      return item.funcName;
    },
    formatInputArguments() {
      // Implement your logic to format input arguments
      return ''; // Placeholder, update as needed
    },
    formatDescription(description) {
      // Implement your logic to format the description
      return description;
    },
    closeResponse(item) {
      // Implement your logic to close the response
      item.showResponse = !item.showResponse;
    },
  },
  mounted() {
    // Attach live hook to the specified event
    // window.cubits.on('NewBlock', (data) => {
    //   // Execute live hook logic here
    //   console.log("---------------------------------data",data)
    //   this.liveHook(data);
    // });
    // setTimeout(() => {
    for (const category in this.categorizedApiLists) {
      if (Object.prototype.hasOwnProperty.call(this.categorizedApiLists, category)) {
        this.categorizedApiLists[category].forEach((event) => {
          window.cubits.on(event.funcName, (data) => {
            if (event.funcName === this.selectedEvent) {
              // Execute live hook logic here
              console.log("---------------------------------data", data);
              this.liveHook(data);
            }
          });
        });
      }
    }
  // }, 2000); // 2000 milliseconds = 2 seconds

    this.populateEventOptions();
  },
};
</script>

<style scoped>

.listening-live {
  position:absolute;
  right:26px;
}

.listening-live span {
  margin-left: 5px;
}

.options-button {
  font-family: 'Roboto', sans-serif; 
  font-size: 14px; 
  color: black; 
  background-color: #f6f6f6; 
  padding: 10px; 
  border-radius: 4px;
}
</style>