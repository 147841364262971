<template>
  <div class="footer-container">
    <div :class="['footer-info', !darkTheme ? 'dark-theme' : 'bright-theme']">
      <div :class="['row align-items-center justify-content-between', !darkTheme ? 'dark-theme' : 'bright-theme']">
        <div class="col-md-3">
          <div class="pt-0 px-2 py-0 m-3">
            <p class="text-white">
              Copyright © {{ new Date().getFullYear() }} Storageprotocol.
            </p>
          </div>
        </div>

        <div class="col-md-6 text-center">
          <i class="fab fa-github mr-3 fa-2x text-white social-icon" @click="openSocials('github')"></i>
          <i class="fab fa-discord mr-3 fa-2x text-white social-icon" @click="openSocials('discord')"></i>
          <i class="fab fa-twitter mr-3 fa-2x text-white social-icon" @click="openSocials('twitter')"></i>
          <i class="fab fa-instagram mr-3 fa-2x text-white social-icon" @click="openSocials('instagram')"></i>
          <i class="fab fa-youtube mr-3 fa-2x text-white social-icon" @click="openSocials('youtube')"></i>
        </div>

        <!-- <div style="text-align: right;flex-grow: 1;"> -->
          <div class="col-md-3 text-right">
          <i class="fa fa-arrow-right mr-2 fa-2x" v-show="!isAdded" @click="AddMetamask" :class="{ 'blinking-arrow': !isAdded }"></i>
          <!-- <i class="fa fa-hand-pointer mr-2 fa-3x" v-show="!isAdded" @click="AddMetamask" :class="{ 'blinking-arrow': !isAdded }" style="transform: rotate(90deg);"></i> -->
          <v-btn :class="['metamask_button text-white', !darkTheme ? 'dark-theme' : 'bright-theme']" :style="{ marginTop: isBlinking ? '-8px' : '0' }" @click="AddMetamask()">
            <span>Add Storage Protocol</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    darkTheme: {
      type: Boolean,
      required: true
    }
  },
  name: 'FooterBar',
  data() {
    return {
      isAdded: localStorage.getItem('isAdded') === 'true', // Check if the button is already clicked
      isBlinking: false // Initially, the button is not blinking
    };
  },
  created() {
    this.isBlinking = !this.isAdded; // If the button hasn't been clicked, start the blinking animation
  },
  methods: {
    AddMetamask() {
      if (window.ethereum) {
        window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [{
            chainId: "0x19105284",
            rpcUrls: ["https://rpc.storageprotocol.com/"],
            chainName: "StorageProtocol Mainnet",
            nativeCurrency: {
              name: "CUBITS",
              symbol: "CUBITS",
              decimals: 18
            },
            blockExplorerUrls: ["https://explorer.storageprotocol.com/"]
          }]
        });
      } else {
        // Handle the case where MetaMask or another Ethereum provider is not installed
        window.alert("Please install MetaMask or another StorageProtocol provider.");
      }


      this.isAdded = true; // Update the flag
      localStorage.setItem('isAdded', 'true'); // Store the flag in localStorage
      this.isBlinking = false; // Stop the blinking animation after the button is clicked
    },
    openSocials(name) {
      switch (name) {
        case 'github':
          window.open('https://github.com/StorageProtocol', '_blank');
          break;
        case 'discord':
          // You can replace 'your-discord-server' with your Discord server invite link
          window.open('https://discord.gg/7k5MFD2msP', '_blank');
          break;
        case 'twitter':
          window.open('https://twitter.com/StorageProtocoI', '_blank');
          break;
        case 'instagram':
          window.open('https://www.instagram.com/storageprotocol/', '_blank');
          break;
        case 'youtube':
          window.open('https://www.youtube.com/user/storageprotocol', '_blank');
          break;
        default:
          break;
      }
    }
  },
};
</script>

<style scoped>
.social-icon {
  cursor: pointer; /* Set cursor to pointer */
}
.footer-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto; /* Set the height as per your previous design */
}

.footer-info {
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  height: 100%; /* Ensure the footer occupies full height */
}

.text-white {
  color: #fff;
}

.v-btn {
  margin: 0; /* Remove margin */
  padding: 10px 20px;
  /* background-color: #007bff !important; */
  box-shadow:none;
  color: #fff;
  border: none;
  border-radius: 0; /* Remove border radius */
  cursor: pointer;
  display: inline-block;
}

.v-btn:hover {
  background-color: #0056b3;
}

.blinking-arrow {
  animation: blinker 2.5s linear infinite;
}

.blinking-arrow:before {
  color: white; /* Set the color to white */
}

@keyframes blinker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
