// src/router.js

import { createRouter, createWebHistory } from 'vue-router';
// import HomePage from './views/HomePage.vue';
import Api1Page from './views/Api1Page.vue';
import Api2Page from './views/Api2Page.vue';
import StorageCharts from './views/StorageCharts.vue';
import DocumentationPage from './views/DocumentationPage.vue';
import DownloadPage from './views/DownloadPage.vue';

const routes = [
  {
    path: '/',
    name: 'WelcomePage',
    meta: {
      title: 'INSTALL | API',
      description: 'Explore comprehensive instructions and usage guidelines for integrating the SDK with your applications.'
    },
    component: DocumentationPage
  },
  {
    path: '/install',
    name: 'Install',
    meta: {
      title: 'INSTALL | API',
      description: 'Explore comprehensive instructions and usage guidelines for integrating the SDK with your applications.'
    },
    component: DocumentationPage
  },
  {
    path: '/api',
    name: 'Api',
    meta: {
        title: 'EVENT HOOKS | API',
        description: 'Using this API you can view web3 functions.'
    },
    component: Api1Page
  },
  {
    path: '/events',
    name: 'Events',
    meta: {
        title: 'EVENT HOOKS | API',
        description: 'Using this API you can directly listen to blockchain events.'
    },
    component: Api2Page
  },
  // {
  //   path: '/sdk',
  //   name: 'Sdk',
  //   meta: {
  //       title: 'EVENT HOOKS | API',
  //       description: 'Using this API you can listen to blockchain events.'
  //   },
  //   component: HomePage
  // },
  {
    path: '/stats',
    name: 'Stats',
    meta: {
        title: 'SATISTICS',
        description: 'Here are some statistics from the API calls.'
    },
    component: StorageCharts
  },
  {
    path: '/download',
    name: 'Download',
    meta: {
        title: 'DOWNLOAD',
        description: 'Using this API you can listen to blockchain events.'
    },
    component: DownloadPage
  }
  // You can add more routes here if needed
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
