<template>
  <div>
    <v-tabs v-model="selectedTab">
      <v-tab v-for="(group, groupName) in chartDataList" :key="groupName" @click="handleTabClick(groupName)">
        {{ groupName }}
      </v-tab>
    </v-tabs>
   
    <v-window v-model="selectedTab">
      <v-window-item v-for="(group, groupName) in chartDataList" :key="groupName" :value="groupName">
        <div :class="['chart-container', !darkTheme ? 'dark-theme2' : 'bright-theme2']">
          <h2 :class="['custom-heading', !darkTheme ? 'dark-theme' : 'bright-theme']">{{ groupName }}</h2>
          
          <div v-for="(chartData, index) in chartDataList[groupName]" :key="index" :class="['chart', !darkTheme ? 'dark-theme5' : 'bright-theme5']">

            <!-- Loader -->
            <div :class="['chart-div', 'loaderChart', darkTheme ? 'bright-theme5' : 'dark-theme5']" v-if="chartData.isLoading || !chartData.isInView || !chartData.firstLoadFinished">
              <v-container class="chart-div">
                <v-row class="fill-height" align-content="center" justify="center">
                  <v-col class="text-subtitle-1 text-center" cols="12">
                    Loading Stats
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear color="grey-darken-2" indeterminate rounded height="6"></v-progress-linear>
                  </v-col>
                </v-row>
              </v-container>
            </div>

            <!-- Chart -->
            <div @scroll="handleScroll">
                <h3 :class="['main-title p-2', !darkTheme ? 'dark-theme3' : 'bright-theme3']">{{ chartData.mainTitle }}</h3>
                <div :ref="'chartDiv'+groupName+index" :class="['chart-div p-2', !darkTheme ? 'dark-theme5' : 'bright-theme5']"></div>
                <chart-heading-description :chartData="chartData"></chart-heading-description>
            </div>

            <!-- Divider -->
            <div :class="['divider-container', !darkTheme ? 'dark-theme2' : 'bright-theme2']">
              <label for="blockrange" class="select-element">Select Blockrange:</label>
              <select id="blockrange" v-model="chartData.blockrange" @change="reloadChart(chartData,groupName,index)" class="select-element-button">
                <option v-for="option in chartData.blockrangeOptions" :value="option" :key="option">{{ option }}</option>
              </select>
              <label for="divider" class="select-element">Select Divider:</label>
              <select id="divider" v-model="chartData.divider" @change="reloadChart(chartData,groupName,index)" class="select-element-button">
                <option v-for="option in chartData.dividerOptions" :value="option" :key="option">{{ option }}</option>
              </select>
            </div>
          </div>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>



<script>
import * as echarts from 'echarts';
import ChartHeadingDescription from '@/components/ChartHeadingDescription.vue'; // Adjust the path as per your project structure


export default {
components: {
  ChartHeadingDescription
},
props: {
  darkTheme: {
    type: Boolean,
    required: true
  }
},
data() {
  return {
    selectedTab: null, // to keep track of the selected tab
    // selectedDivider: 5,
    // dividerOptions: [5, 10, 20, 30, 40, 50], // Array of possible divider options
    chart_ids : {},
    all_unique_charts : {},
    chartDataList: {
      "supply": [
        { mainTitle: "Supply Distribution", XLabel: ["Blocks","Blocks","Blocks"], YLabel: ["Cubits","Cubits","Cubits"], functionName: ["circulating_supply_cubits", "real_supply_cubits", "total_supply_cubits"], title: ["Circulating Supply", "Real Supply", "Total Supply"], convertFunc: [this.toWeiConvert, this.toWeiConvert, this.toWeiConvert], options: "gradientStackedAreaChart" },
        // { mainTitle: "Cubits to USDT", XLabel: "Blocks", YLabel: "$", functionName: "cubits_to_usdt", title: "Cubits to USDT", convertFunc: this.toWeiConvert, options: "linear" },
        { mainTitle: "USDT to Cubits", XLabel: "Blocks", YLabel: "Cubits", functionName: "usdt_to_cubits", title: "USDT to Cubits", convertFunc: this.toWeiConvert, options: "linear" },
        { mainTitle: "Total Supply", XLabel: "Blocks", YLabel: "Cubits", functionName: "total_supply_cubits", title: "Total Supply", convertFunc: this.toWeiConvert, options: "linear" },
        { mainTitle: "Real Supply", XLabel: "Blocks", YLabel: "Cubits", functionName: "real_supply_cubits", title: "Real Supply", convertFunc: this.toWeiConvert, options: "linear" },
        { mainTitle: "Circulating Supply", XLabel: "Blocks", YLabel: "Cubits", functionName: "circulating_supply_cubits", title: "Circulating Supply", convertFunc: this.toWeiConvert, options: "linear" },
        { mainTitle: "Created vs Deleted", XLabel: ["Blocks","Blocks"], YLabel: ["Cubits","Nodes"], functionName: ["createdCubits", "deletedCubits"], title: ["Created", "Deleted"], convertFunc: [this.toWeiConvert, this.toWeiConvert], options: "stackedAreaChart" },
      ],
      "storage": [
        { mainTitle: "Total Contracts", XLabel: "Blocks", YLabel: "Count", functionName: "contractsTotalCount", title: "Total Contracts", convertFunc: this.noConvert, options: "bar" },
        { mainTitle: "Total Contract Size", XLabel: "Blocks", YLabel: "GB", functionName: "contractsTotalSize", title: "Total Contract Size", convertFunc: this.toSizeConvert, options: "bar" },
        { mainTitle: "Available Storage Size", XLabel: "Blocks", YLabel: "GB", functionName: "getAvailableStorageSize", title: "Available Storage Size", convertFunc: this.toSizeConvert, options: "linear" },
        // { mainTitle: "Usable Storage Size", XLabel: "Blocks", YLabel: "GB", functionName: "getUsableStorageSize", title: "Usable Storage Size", convertFunc: this.toSizeConvert, options: "linear" },
        { mainTitle: "Usable Storage Size", XLabel: ["Blocks","Blocks"], YLabel: ["GB","GB"], functionName: ["getAvailableStorageSize", "contractsTotalSize"], title: ["Available Storage Size", "Used Storage Size"], convertFunc: [this.toSizeConvert, this.toSizeConvert], options: "gradientStackedAreaChart" },
        { mainTitle: "Contracts Total Size", XLabel: "Blocks", YLabel: "GB", functionName: "contractsTotalSize", title: "Contracts Total Size", convertFunc: this.toSizeConvert, options: "linear" },
        { mainTitle: "Hosting Cost Per Day", XLabel: "Blocks", YLabel: "Cubits", functionName: "getHostingCostNodePerDay", title: "Hosting Cost Per Day", convertFunc: this.toWeiConvert, options: "bar" },
      ],
      "nodes": [
        { mainTitle: "Collateral Requirement", XLabel: "Blocks", YLabel: "Cubits", functionName: "getCurrentCollateralRequirement", title: "Collateral Requirement", convertFunc: this.toWeiConvert, options: "bar" },
        { mainTitle: "Daily Reward", XLabel: "Blocks", YLabel: "Cubits", functionName: "getDailyRewardShare", title: "Daily Reward", convertFunc: this.toWeiConvert, options: "bar" },
        { mainTitle: "Node Reward vs Node Count", XLabel: ["Blocks","Blocks","Blocks"], YLabel: ["Cubits","Cubits","Nodes"], functionName: ["getDailyRewardShare", "nodeCubits", "active_nodeCount"], title: ["Node Share","Node Reward", "Node Count"], convertFunc: [this.toWeiConvert,this.toWeiConvert, this.noConvert], options: "gradientStackedAreaChart" },
        { mainTitle: "Node Reward vs Node Count2", XLabel: ["Blocks","Blocks"], YLabel: ["Cubits","Nodes"], functionName: ["getDailyRewardShare", "active_nodeCount"], title: ["Node Reward", "Node Count"], convertFunc: [this.toWeiConvert, this.noConvert], options: "gradientStackedAreaChart" },
        { mainTitle: "Daily Node Reward", XLabel: "Blocks", YLabel: "Cubits", functionName: "nodeCubits", title: "Daily Node Reward", convertFunc: this.toWeiConvert, options: "linear" },
        { mainTitle: "Daily Node Count", XLabel: "Blocks", YLabel: "Nodes", functionName: "active_nodeCount", title: "Daily Node Count", convertFunc: this.noConvert, options: "linear" },
       
        { mainTitle: "Contracts Total Size", XLabel: "Blocks", YLabel: "GB", functionName: "contractsTotalSize", title: "Contracts Total Size", convertFunc: this.toSizeConvert, options: "linear" },

        { mainTitle: "Available vs Used Storage Size", XLabel: ["Blocks","Blocks"], YLabel: ["GB","GB"], functionName: ["getAvailableStorageSize", "contractsTotalSize"], title: ["Available Storage Size", "Used Storage Size"], convertFunc: [this.toSizeConvert, this.toSizeConvert], options: "stackedAreaChart" },
      ],
      "multisig": [
        { mainTitle: "Required Signatures", XLabel: "Blocks", YLabel: "Signatures", functionName: "multisig_required", title: "Required Signatures", convertFunc: this.noConvert, options: "bar" },
      ],
    }

  };
},
mounted() {
  this.selectedTab = Object.keys(this.chartDataList)[0];
  this.addExtraProperties()
  window.addEventListener('resize', this.handleResize);
  window.addEventListener('scroll', this.handleScroll);
  // this.handleScroll(); // Check visibility on initial mount
  this.$nextTick(() => {
      this.handleScroll(Object.keys(this.chartDataList)[0]);
  });
  this.initCharts();

},
// unmounted() {
//   window.removeEventListener('resize', this.handleResize);
// },
// beforeUnmount() {
//   window.removeEventListener('resize', this.handleResize);
//   window.removeEventListener('scroll', this.handleScroll);
// },
methods: {
  handleTabClick(groupName) {
    // Call handleScroll after changing the tab
    this.selectedTab = groupName;
    // this.handleScroll(groupName);
    this.$nextTick(() => { // important because v-window takes 1 tick to enter
  
        this.handleScroll(groupName);
    });
  },
  NumberFormatting(value) {
    const decimals = 2
    const num = Number(value);
    if (num >= 1e9) {
        return "~" + (num / 1e9).toFixed(2) + "B";
    } else if (num >= 1e6) {
        return "~" + (num / 1e6).toFixed(2) + "M";
    } else if (num >= 1e3) {
        return "~" + (num / 1e3).toFixed(2) + "k";
    } else {
        return "~" + num.toFixed(decimals);
    }
  },
  GetChartStringLength(data,chartdata) {
    const totalStringLength = data.reduce((acc, item) => {
        if (typeof item.value === 'string') { // data from linear chart contains name and value entries..
            return acc + item.value.length;
        } else {
          if (typeof item === 'string') { // data from stackchart
            return acc + item.length;
          } else {
            return acc;
          }
        }
    }, 0);
    // console.log("chartDatawwww",window.innerWidth,chartData.divider,label_count)
    
    let free_width = (totalStringLength*(chartdata.divider/5)*5 / window.innerWidth)
    // console.log("totalStringLength",data)
    // console.log("totalStringLength",totalStringLength)
    // console.log("totalStringLength 2",free_width)

    // if (window.innerWidth < 600) {
    //   console.log("CHECKING VIS BEF", chartdata)
    //   if (chartdata.option.yAxis.axisLabel) {
    //     chartdata.option.yAxis.axisLabel.show = false;
    //   }
      
    // } else {
    //   if (chartdata.option.yAxis.axisLabel) {
    //     chartdata.option.yAxis.axisLabel.show = true;
    //   }
    // }
    // console.log("CHECKING VIS AFT", chartdata)



    // Math.min(minSupply, supply);
    
    return Math.max(1, parseInt(free_width))
  },
  addExtraProperties() {
    for (const category of Object.values(this.chartDataList)) {
    // Loop through each item in the category
    for (const item of category) {
      // Initialize the 'option' attribute if it doesn't exist
      if (!item.option) {
        item.option = {};
        item.firstLoadFinished = false;
        item.isLoading = false;
        item.isInView = false;
        item.divider = 10;
        item.blockrange = 100;
        item.blockrangeOptions = [100,200,500,1000,50000,10000];
        item.dividerOptions = [5, 10, 20, 30, 40, 50];
      }
    }
  }

  },
  noConvert(_input) {
    return _input;
  },
  toWeiConvert(_input) {
    return window.cubits.web3.utils.fromWei(_input, 'ether');
    // return window.cubits.web3.utils.fromWei(_input, 'ether').div("1000");
    // return (parseFloat(window.cubits.web3.utils.fromWei(_input, 'ether'))/1000000).toString() + "mil";
    // return parseFloat(window.cubits.web3.utils.fromWei(_input, 'ether'))/1000000;
  },
  toSizeConvert(_input) {
    // console.log("aaaa",_input)
    // return window.cubits.utility.getStorageSize(_input);
    return _input/1000000000;
  },
  hslToRgb2(h, s, l) {
      // console.log(h, s, l)
      let r, g, b;

      if (s === 0) {
          r = g = b = l; // achromatic
      } else {
          const hue2rgb = function hue2rgb(p, q, t) {
              if (t < 0) t += 1;
              if (t > 1) t -= 1;
              if (t < 1 / 6) return p + (q - p) * 6 * t;
              if (t < 1 / 2) return q;
              if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
              return p;
          };

          const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
          const p = 2 * l - q;
          r = hue2rgb(p, q, h + 1 / 3);
          g = hue2rgb(p, q, h);
          b = hue2rgb(p, q, h - 1 / 3);
      }

      return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  },
  hslToRgb(h, s, l) {
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = function hue2rgb(p, q, t) {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }
    console.log(r,g,b)
    return [r,70,225];
  },
  
  // getRenderRef(groupname,index) {
  //   let _ref = this.$refs['chartRender' + groupname +index]
  //   if (_ref && _ref.length > 0){

  //     return _ref[0]
  //   } else {
  //     return _ref
  //   }
  // },
  getRef(groupname,index) {
    let _ref = this.$refs['chartDiv' + groupname +index]
    if (_ref && _ref.length > 0){

      return _ref[0]
    } else {
      return _ref
    }
  },
  handleObjectVisibility(groupName,groupData) {
  
    const view_offset = 0;
    for (let i = 0; i < groupData.length; i++) {
          const element = this.getRef(groupName, i);
          if (!element || !(element instanceof HTMLElement)) continue;
          
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;
          
          if (rect.top >= 0 && rect.bottom <= windowHeight + view_offset) {
              if (!groupData[i].isInView) {
                  groupData[i].isInView = true;
                  this.drawChart(groupData[i], groupName, i);
                  this.drawChart(groupData[i], groupName, i); // Note: Need to do this two times
              }
          }
      }
  },
  handleScroll(groupName) {
    let groupData = groupName ? this.chartDataList[groupName] : null;

    if (!groupData) {
        for (const [groupName, data] of Object.entries(this.chartDataList)) {
            groupData = data;
            this.handleObjectVisibility(groupName,groupData)
        }
    } else {
      this.handleObjectVisibility(groupName,groupData)
    }
  },

  divideBlocks(blockRange, distance, currentBlockNR) {

    // console.log("DIVIDING BLOCKS",blockRange, distance, currentBlockNR)
    // Calculate the number of divisions based on the specified distance
    let take_range = blockRange < currentBlockNR ? blockRange : currentBlockNR

    const divisions = Math.max(1, Math.ceil(take_range / distance));

    // Calculate the step value for each division
    const step = Math.ceil(take_range / divisions);

    // Initialize an array to store the results
    let results = [];
    
    // Loop through the number of divisions and calculate the result
    for (let i = 0; i < step; i++) {
        const result = Math.max(1, currentBlockNR - (take_range - i * divisions));
        results.push(result);
    }
    results.push(currentBlockNR);

    // Remove duplicate results
    results = Array.from(new Set(results));
    // this.chartData.blocks = results
    
    return results;
  },
  async drawChart(chartData,groupName,i) {
    // let chartindex = i;
    // const chartDiv = this.getRef(groupName,i);
    if (!this.all_unique_charts[groupName+i]) {
      const chartDiv = this.getRef(groupName,i);
      this.chart_ids[groupName+i] = Object.keys(this.chart_ids).length;
      // chartDiv.style.height = '400px';
      const chart = echarts.init(chartDiv);
      // console.log("CREATIN CHART",chart)
      this.all_unique_charts[groupName+i] = chart
      
    }

    
    const formatterFunction = (params, data, chartData,series_index) => {
      let index = params.dataIndex;
      let label_count = this.GetChartStringLength(data, chartData);
      let label_name = Array.isArray(chartData.YLabel) ? chartData.YLabel[series_index] : chartData.YLabel; // choose entry from list if ylabel if not single ylabel exists
      if (index % label_count === 0) { // Show label for every nth data point
          let value = params.value;
          if (!isNaN(value) && value.toString().includes('.')) {
              value = this.NumberFormatting(value);
          }
          return `${value} ${label_name}`;
      } else {
          return ''; // Return empty string for odd indices
      }
    };
        



    if (Object.keys(chartData.option).length === 0) {
      const data = [];
      let minSupply = Infinity;
      let maxSupply = -Infinity;
      let distance = 100;
      const currentBlockNR = await window.cubits.web3.eth.getBlockNumber();
      // const divider = chartData.divider; // Accessing the divider property for the current chart data
      // const interval = parseInt(currentBlockNR / divider);

      // chartData.block_range

      // const block_range = 10000
      // blockrangeOptions
      // dividerOptions
      console.log("chartData",chartData)
      let _blocks = this.divideBlocks(chartData.blockrange,chartData.divider,currentBlockNR)
      // let _blocks = [1673,1500,1400,1300,1200,1100,700,300,200,150]

      // const saturation = 100;
      // const lightness = 30;
      // var option = {}
      
      const pre_colors = {
        0:`rgba(75,75,75, 0.9)`,
        1:`rgba(9,87,243, 0.9)`,
        2:`rgba(140,176,253, 0.9)`,
      }

      const colors = [];
      for (let i = 0; i < chartData.title.length; i++) {
          // const hue = (360 / chartData.title.length) * (i+chartindex*3);
          
          // const rgbColor = this.hslToRgb(hue / 360, saturation / 100, lightness / 100);
          // const color = `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, 0.9)`;
          // const color = `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, 0.9)`;
          // colors.push(color);
          colors.push(pre_colors[i%pre_colors.length]);
      }

      // const label_count = parseInt(1 / window.innerWidth / 100 / chartData.divider) // how many labels per graph can be shown
      // var label_count = parseInt(1 / ((window.innerWidth/chartData.divider/25) / 10)) // how many labels per graph can be shown 22 is fixed len ! could do via string len but more complicated
      // Calculate the total length of all string values in the data array

      var label_count = Math.min(1, parseInt(chartData.divider/5)) // how many labels per graph can be shown 22 is fixed len ! could do via string len but more complicated

      console.log("chartDatawwww",window.innerWidth,chartData.divider,label_count)


      if (chartData.options == "linear") {

          // Calculate colors for each series

          for (let blockNumber of _blocks) {
              let supply = await window.cubits.api.data.callPastFunction(chartData.functionName, blockNumber);
              supply = chartData.convertFunc(supply);
              data.push({ value: supply, name: blockNumber }); // Using block number as the x-axis value
              minSupply = Math.min(minSupply, supply);
              maxSupply = Math.max(maxSupply, supply);
          }
          distance = Math.abs(maxSupply - minSupply)/2;

          // let str_len = (maxSupply.toString().length + 2 + chartData.YLabel.toString().length)*1.5
          // label_count = parseInt(1 / ((window.innerWidth/chartData.divider/str_len) / chartData.divider)) // how many labels per graph can be shown

          chartData.option = {
              title: {
                  text: chartData.mainTitle,
                  // text: window.cubits.api.data.apiDescriptionsObject[chartData.title].description,
                  left: 'center'
              },
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'cross',
                      label: {
                          backgroundColor: '#6a7985'
                      }
                  },
                  formatter: function(params) {
                      let tooltipText = `Block: ${params[0].name}<br/>`;
                      params.forEach(param => {
                          tooltipText += `${param.seriesName}: ${param.value}<br/>`;
                      });
                      return tooltipText;
                  }
              },
              xAxis: {
                  type: 'category',
                  data: data.map(item => item.name) // Using block number as the x-axis data
              },
              yAxis: {
                  type: 'value',
                  min: (-parseInt((maxSupply - minSupply) / 2) + parseInt(minSupply / distance) * distance) > 0 ? (-parseInt((maxSupply - minSupply) / 2) + parseInt(minSupply / distance) * distance) : null
              },
              series: [{
                data: data.map(item => ({
                    value: item.value,
                    label: {
                        show: true,
                        position: 'top'
                    }
                })),
                type: 'line',
                lineStyle: {
                    color: pre_colors[Object.keys(pre_colors)[this.chart_ids[groupName + i] % Object.keys(pre_colors).length]], // Set the color for the line series
                    width: 6
                }
            }]
          };
      }
      else if (chartData.options == "bar") {
        label_count = 1
          for (let blockNumber of _blocks) {
              let supply = await window.cubits.api.data.callPastFunction(chartData.functionName, blockNumber);
              supply = chartData.convertFunc(supply);
              data.push({ value: supply, name: blockNumber }); // Using block number as the x-axis value
              minSupply = Math.min(minSupply, supply);
              maxSupply = Math.max(maxSupply, supply);
          }
          distance = Math.abs(maxSupply - minSupply)/2;

          chartData.option = {
            title: {
              text: chartData.mainTitle,
              left: 'center'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              },
              formatter: function(params) {
                const data = params[0];
                return `Block: ${data.name}<br/>Value: ${data.value}`;
              }
            },
            xAxis: {
              type: 'category',
              data: data.map(item => item.name) // Using block number as the x-axis data

            },
            yAxis: {
              type: 'value',
              min: (-parseInt((maxSupply - minSupply) / 2) + parseInt(minSupply / distance) * distance) > 0 ? (-parseInt((maxSupply - minSupply) / 2) + parseInt(minSupply / distance) * distance) : null


            },
            series: [{
              data: data.map(item => ({
                value: item.value,
                itemStyle: { color: pre_colors[Object.keys(pre_colors)[this.chart_ids[groupName + i] % Object.keys(pre_colors).length]] } // Set color for the bars
              })),
              type: 'bar',
              label: {
                show: true, // Show labels on bars
                position: 'top' // Position labels on top of bars
              }
            }]
          };
        }
      else if (chartData.options == "pie") {

        let _series = []
        let _legends = []
        for (let _func of chartData.title) {
        // for (let _func of chartData.functionName) {
          _legends.push(_func)
        }

        for (let i = 0; i < chartData.title.length; i++) {
          let _title = chartData.title[i];
          let _func = chartData.functionName[i]
          let _convert = chartData.convertFunc[i]
          // console.log(`Index: ${i}, Title: ${_title}`);
      
          let _data = []
          for (let blockNumber of _blocks) {
            // console.log("aaaa1",_func,blockNumber)
            let supply = await window.cubits.api.data.callPastFunction(_func, blockNumber);
            // console.log("aaaa2",supply)
            // supply
            // supply = window.cubits.web3.utils.fromWei(supply, 'ether');
            supply = _convert(supply)
            _data.push(supply); // Using block number as the x-axis value
            minSupply = Math.min(minSupply, supply);
            maxSupply = Math.max(maxSupply, supply);
          }
          distance = Math.abs(maxSupply - minSupply)/2;

          _series.push({
            name: _title,
            type: 'line',
            // stack: 'Total',
            // stack: 'difference',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: _data
          })
        }

        chartData.option = {
          title: {
            text: chartData.mainTitle
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: _legends
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: _blocks
            }
          ],
          yAxis: [
            {
              type: 'value',
              min:(-parseInt((maxSupply-minSupply)/2)+parseInt(minSupply/distance)*distance)
            }
          ],
          series: _series
        };

      }
      else if (chartData.options == "stackedAreaChart" ) {


        // let _blocks = []
        // for (let n = divider; n >= 0; n--) {

        //   _blocks.push(currentBlockNR - n * interval)
        // }


        let _series = []
        let _legends = []
        for (let _func of chartData.title) {
        // for (let _func of chartData.functionName) {
          _legends.push(_func)
        }

        for (let i = 0; i < chartData.title.length; i++) {
          let _title = chartData.title[i];
          let _func = chartData.functionName[i]
          let _convert = chartData.convertFunc[i]
          // console.log(`Index: ${i}, Title: ${_title}`);
      
          let _data = []
          for (let blockNumber of _blocks) {
            // console.log("aaaa1",_func,blockNumber)
            let supply = await window.cubits.api.data.callPastFunction(_func, blockNumber);
            // console.log("aaaa2",supply)
            // supply
            // supply = window.cubits.web3.utils.fromWei(supply, 'ether');
            supply = _convert(supply)
            _data.push(supply); // Using block number as the x-axis value
            minSupply = Math.min(minSupply, supply);
            maxSupply = Math.max(maxSupply, supply);
            
          }
          distance = Math.abs(maxSupply - minSupply)/2;

          _series.push({
            name: _title,
            type: 'line',
            // stack: 'Total',
            // stack: 'difference',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: _data,
            label: {
              show: true,
              position: 'top'
            }
          })
        }

        chartData.option = {
          title: {
            text: chartData.mainTitle
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: _legends
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: _blocks
            }
          ],
          yAxis: [
            {
              type: 'value',
              min:(-parseInt((maxSupply-minSupply)/2)+parseInt(minSupply/distance)*distance)
            }
          ],
          series: _series
        };


      }
      else if (chartData.options == "gradientStackedAreaChart") {
        let _series = [];
        let _legends = [];

        for (let _func of chartData.title) {
            _legends.push(_func);
        }

        for (let i = 0; i < chartData.title.length; i++) {
          let _title = chartData.title[i];
          let _func = chartData.functionName[i];
          let _convert = chartData.convertFunc[i]

          let _data = [];
          for (let blockNumber of _blocks) {
              let supply = await window.cubits.api.data.callPastFunction(_func, blockNumber);
              // supply = window.cubits.web3.utils.fromWei(supply, 'ether');
              supply = _convert(supply)
              _data.push(supply);
              minSupply = Math.min(minSupply, supply);
              maxSupply = Math.max(maxSupply, supply);
          }
          distance = Math.abs(maxSupply - minSupply)/2;

          // // Calculate hue based on index i to evenly distribute colors in the HSL color wheel
          // const hue = (360 / chartData.title.length) * i;

          // // Convert HSL to RGB color
          // const rgbColor = this.hslToRgb(hue / 360, saturation / 100, lightness / 100);

          // Format the RGB values for use in the gradient
          // const color1 = `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, 0.9)`;
          // const color2 = `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, 0.1)`;
          const color1 =  pre_colors[0];
          const color2 = pre_colors[1];

          _series.push({
              name: _title,
              type: 'line',
              smooth: true,
              lineStyle: {
                  width: 0
              },
              areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: color1 },
                      { offset: 1, color: color2 }
                  ])
              },
              emphasis: {
                  focus: 'series'
              },
              data: _data,
              label: {
                show: true,
                position: 'top'
              }
          });
      }


        chartData.option = {
            title: {
                text: chartData.mainTitle,
                left: 'center',
                top: '28px',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                data: _legends
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    data: _blocks
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    min:(-parseInt((maxSupply-minSupply)/2)+parseInt(minSupply/distance)*distance)
                }
            ],
            series: _series
        };


      }

      
      // chartData.option.xAxis[0].Name = chartData.XLabel
      // chartData.option.yAxis[0].Name = chartData.YLabel

      // Apply formatter function to the label property inside each data object within series
      if (data) {
        
          chartData.option.series.forEach((seriesObj, seriesIndex) => {
            console.log("seriesObj.label",seriesObj.label)
              if (seriesObj.label) {
                seriesObj.label.formatter = (params) => formatterFunction(params, seriesObj.data, chartData,seriesIndex);

              } else { // linear chart
                seriesObj.data.forEach(dataObj => {
                  // dataObj.label = dataObj.label || {}; // Ensure label object exists
                  if (dataObj.label) {
                    dataObj.label.formatter = (params) => formatterFunction(params, data, chartData);
                  } 
                  
                });
              }


              // seriesObj.label.formatter = (params) => formatterFunction(params, data, chartData);
          });

          // chartData.option.yAxis.forEach((axisObj, seriesIndex) => {
          // chartData.option.yAxis.forEach((axisObj) => {
          //     console.log("seriesObj.axisLabel",axisObj.axisLabel);
          //     if (axisObj.axisLabel) {
          //         axisObj.axisLabel.formatter = (value) => value.toFixed(2);
          //     } else { // linear chart
          //         axisObj.data.forEach(dataObj => {
          //             // dataObj.axisLabel = dataObj.axisLabel || {}; // Ensure axisLabel object exists
          //             if (dataObj.axisLabel) {
          //                 dataObj.axisLabel.formatter = (value) => value.toFixed(2);
          //             } 
          //         });
          //     }
          // });

    }
      

    // For X-axis
    if (chartData.option.xAxis) {
      if (chartData.option.xAxis[0]) {
        chartData.option.xAxis[0].name = Array.isArray(chartData.XLabel) ? chartData.XLabel[0] : chartData.XLabel;
      } else {
        chartData.option.xAxis.name = Array.isArray(chartData.XLabel) ? chartData.XLabel[0] : chartData.XLabel;
      }
      console.log("Setting xAxis name:", Array.isArray(chartData.XLabel) ? chartData.XLabel[0] : chartData.XLabel);
    }

    // For Y-axis
    if (chartData.option.yAxis) {
      if (chartData.option.yAxis[0]) {
        
        chartData.option.yAxis[0].name = Array.isArray(chartData.YLabel) ? chartData.YLabel[0] : chartData.YLabel;
      } else {
        chartData.option.yAxis.name = Array.isArray(chartData.YLabel) ? chartData.YLabel[0] : chartData.YLabel;
      }
      console.log("Setting yAxis name:", Array.isArray(chartData.YLabel) ? chartData.YLabel[0] : chartData.YLabel);
    }

    if (chartData.option.yAxis) {
      if (Array.isArray(chartData.option.yAxis)) {
          // If yAxis is an array, set the axisLabel property of each item
          for (let i = 0; i < chartData.option.yAxis.length; i++) {
              if (!chartData.option.yAxis[i].axisLabel) {
                  chartData.option.yAxis[i].axisLabel = {
                      show: true,
                      rotate: 0,
                      margin: 8,
                      color: pre_colors[i%pre_colors.length], // Label text color
                      fontSize: 12, // Label font size
                      formatter: (value) => value.toFixed(2)
                  };
              }
          }
      } else {
          // If yAxis is not an array, directly set the axisLabel property
          if (!chartData.option.yAxis.axisLabel) {
              chartData.option.yAxis.axisLabel = {
                  show: true,
                  rotate: 0,
                  margin: 8,
                  color: pre_colors[i%pre_colors.length], // Label text color
                  fontSize: 12, // Label font size
                  formatter: (value) => value.toFixed(2)
              };
          }
          // chartData.option.yAxis.axisLabel.formatter = (value) => value.toFixed(2);
      }
    }

    chartData.option.title = ""; // remove title



    }

    // chart.setOption(option);
    this.all_unique_charts[groupName+i].setOption(chartData.option);
    chartData.firstLoadFinished = true;
    chartData.isLoading = false;

  },
  async initCharts() {
    for (const [groupName, groupData] of Object.entries(this.chartDataList)) {
      for (let i = 0; i < groupData.length; i++) {
        if (groupData[i].isInView == true) {
          this.drawChart(groupData[i],groupName,i)
          // this.drawChart(groupData[i],groupName,i)
        }
      }
    }
  },
  handleResize() {
    
    for (const [groupName, groupData] of Object.entries(this.chartDataList)) {
      // for (let i = 0; i < groupData.length; i++) {
        for (let i = 0; i < groupData.length; i++) {
          if (groupData[i].isInView == true) {
 
            // console.log("CREATIN CHART resize CHECKING VIS AFT", groupData[i])

            // console.log("RESIING",groupName,i)
            // const chart = echarts.getInstanceByDom(this.getRef(groupName,i));
            const chart = this.all_unique_charts[groupName+i]
            // const chart = echarts.getInstanceByDom(this.all_unique_charts[groupName+i])
            if (chart) {
              // chart.resize();
              // const containerWidth = chart.offsetWidth -5;
              // const containerHeight = chart.offsetHeight -5;
        
              // if (window.innerWidth < 600) {
              //   if (groupData[i].option.yAxis.axisLabel) {
              //     groupData[i].option.yAxis.axisLabel.show = false;
              //   }
                
              // } else {
              //   if (groupData[i].option.yAxis.axisLabel) {
              //     groupData[i].option.yAxis.axisLabel.show = true;
              //   }
              // }
              if (window.innerWidth < 600) {
                  if (Array.isArray(groupData[i].option.yAxis)) {
                      // If yAxis is an array, iterate over each item and set axisLabel.show to false
                      for (let j = 0; j < groupData[i].option.yAxis.length; j++) {
                          if (groupData[i].option.yAxis[j].axisLabel) {
                              groupData[i].option.yAxis[j].axisLabel.show = false;
                          }
                      }
                  } else {
                      // If yAxis is not an array, directly set axisLabel.show to false
                      if (groupData[i].option.yAxis.axisLabel) {
                          groupData[i].option.yAxis.axisLabel.show = false;
                      }
                  }
              } else {
                  if (Array.isArray(groupData[i].option.yAxis)) {
                      // If yAxis is an array, iterate over each item and set axisLabel.show to true
                      for (let j = 0; j < groupData[i].option.yAxis.length; j++) {
                          if (groupData[i].option.yAxis[j].axisLabel) {
                              groupData[i].option.yAxis[j].axisLabel.show = true;
                          }
                      }
                  } else {
                      // If yAxis is not an array, directly set axisLabel.show to true
                      if (groupData[i].option.yAxis.axisLabel) {
                          groupData[i].option.yAxis.axisLabel.show = true;
                      }
                  }
              }


              try {
                  // console.log("CREATIN CHART resize",chart,groupName,i)
                  chart.setOption(groupData[i].option); // required to apply axislabel changes !!
                  chart.resize();
              } catch (error) {
                  // console.error('An error occurred while resizing the chart:', error);
              }

            }
          }
        }
    }
  },
  async reloadChart(chartData,groupName,i) {
    
    console.log("chartData", chartData);

    chartData.isLoading = true;
      // this.reloadCount += 1;

      // let intervalId = setInterval(() => {

      //     clearInterval(intervalId); // Stop the interval
      //     chartData.isLoading = false; // Hide the loader

      // }, 500); 


    chartData.option = {}
    // await this.drawChart(chartData,groupName,i);
    await this.drawChart(chartData,groupName,i);
   }
  }
  // Your existing methods
};
</script>

<style scoped>
.chart-container {
/* max-height: 80vh;
overflow-y: auto; 
display: flex;
flex-wrap: wrap;
justify-content: center; */
padding: 10px;
}

.chart {
width: 100%; /* Take full width on small screens */
margin-bottom: 20px;
}

.chart-div {
width: 100%;
height: 400px;
}

.divider-container {
display: flex;
align-items: center;
}

label {
margin-right: 10px;
color: #333; /* Adjust the color as needed */
}

select {
padding: 8px;
border-radius: 5px;
border: 1px solid #ddd; /* Adjust the border color as needed */
background-color: #f3f3f3; /* Adjust the background color as needed */
font-size: 16px; /* Adjust the font size as needed */
}

select:focus {
outline: none;
border-color: #007bff; /* Adjust the focus border color as needed */
}

.select-element,
.select-element-button {
  height: 20px;
  font-size: 14px;
  padding: 8px;
  line-height: 1;
  text-align: center;
}

.select-element-button {
  height: 28px;
  font-size: 12px;
  cursor: pointer;
}

.loaderChart { /* use to animate overlay of reloading */
    position:absolute;
    z-index:9999;
}

.custom-heading {
    text-align: center;
    border-bottom: 2px solid #333; /* You can adjust the border color and thickness as needed */
    padding: 10px 0; /* Adjust the padding as needed */
  }

.main-title {
  font-size: 24px;
  margin-bottom: 10px;
}

</style>
