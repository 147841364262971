import { createVuetify } from 'vuetify';
import * as components from 'vuetify/lib/components';
import * as directives from 'vuetify/lib/directives';

export default function createPlugin(app) {
    const vuetify = createVuetify({
        components,
        directives
    });
  
    app.use(vuetify);
}