import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router configuration
import VueProgressBar from "@aacassandra/vue3-progressbar";
// import { createVuetify } from 'vuetify'; // Import createVuetify from Vuetify
import createVuetifyPlugin from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css'; // Import Vuetify CSS
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.min.css';
// import BootstrapVue from 'bootstrap-vue';

// css and scss files include
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/font-awesome.min.css';
import './assets/index.scss';

const options = {
  color: '#7DEADF',
  failedColor: '#7DEADF',
  thickness: '4px',
  transition: {
    speed: '0.5s',
    opacity: '0.10s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
};

// const vuetify = createVuetify(); // Create Vuetify instance

const app = createApp(App);

createVuetifyPlugin(app);

// app.use(vuetify); // Use Vuetify instance
app.use(VueProgressBar, options);
// app.use(BootstrapVue);
app.use(router);
app.mount('#app');
